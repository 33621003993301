<template>
    <div v-if="loading == false">
        <ChirpListClient
            :data_source="rows"
            :export_api="export_api"
            :target_page="targetPage"
            :createAction="false"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :column_meta="columnMeta"
            :totalCount="totalCount"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
        >
            <template control="">
                <button
                    class="primary"
                    @click="
                        () => {
                            $store.commit('modals/updateField', {
                                field: 'newClientModal',
                                value: !$store.state.modals.newClientModal,
                            });
                        }
                    "
                >
                    + New Clients
                </button>
            </template>
        </ChirpListClient>
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import ChirpListClient from '@/components/general/list/ChirpListClient.vue';
    import NewClientModal from '@/components/client-manager/NewClientModal.vue';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import { Roles } from '@/util/globalConstants';

    const api_root = 'clients';

    export default {
        name: 'Clients',
        components: { ChirpListClient, NewClientModal },
        props: {
            overrideFilter: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                rows: [],
                targetPage: 'ClientOverview',
                loading: true,
                loadingTable: false,
                useOverride: false,
                showNewClientModal: false,
                totalCount: 0,
                Roles,
                export_api: '/clients',
                columnMeta: [
                    { field: 'first_name', headerText: 'First Name' },
                    { field: 'last_name', headerText: 'Last Name' },
                    { field: 'status', headerText: 'Status' },
                    { field: 'email', headerText: 'Email', width: 220 },
                    { field: 'counselor', headerText: 'Counselor' },
                    { field: 'client_balance', headerText: 'Member Balance', align: 'Right', headerAlign: 'Right' }, // WTH?!?!? numbers are NOT Left Justified!!! do not change again
                    { field: 'dob', hide: 1 },
                    { field: 'tags', headerText: 'Project Tags' },
                    // { field: 'age_group', headerText: 'Theme', },
                    // { field: 'profile_name', headerText: 'Assigned Profile', },
                    { field: 'dashboardNames', headerText: 'Dashboards Assigned' },
                ],
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'clients';
            },
            // showNewClientModal(){
            // commit('updateField', {
            //         field: 'newClientModal',
            //         value: !$store.state.modals.newClientModal,
            //     });
            // }
        },
        methods: {
            async load_data() {
                try {
                    let status = this.criteria.filter?.status?.filter((item) => item !== 'STAR-Kids Approved');

                    if (
                        this.criteria.filter?.status?.includes('STAR-Kids Approved') &&
                        !this.criteria.filter?.status?.includes('Active')
                    ) {
                        status.push('Active');
                    }

                    const body = {
                        // criteria: this.criteria
                        criteria: {
                            ...this.criteria,
                            filter: {
                                'clients.status': status,
                                'clients.tags': this.criteria.filter.tags,
                            },
                        },
                        application: this.criteria.filter.status?.includes('STAR-Kids Approved') ? 'star-kid' : '',
                    };

                    if (!this.criteria.filter.status?.includes('STAR-Kids Approved')) {
                        delete body.application;
                    }

                    const res = await this.$api.post(this.export_api, body);

                    if (res.status >= 400) {
                        this.$toasted.error('Failed to fetch clients roster.');
                        return;
                    }

                    let checkIfClient = this.$store.state.user.role_id == Roles.SUPER_ADMIN;

                    const newArray = res.data.rows
                        ?.slice()
                        .reverse()
                        .map((obj) => {
                            let newObj = { ...obj };

                            let tags = JSON.parse(newObj.tags) ?? [];

                            newObj.tags = tags.map((item) => item.name);

                            if (checkIfClient == false) {
                                delete newObj['dashboardNames'];
                            }

                            delete newObj['guardian_name'];

                            return newObj;
                        });

                    this.rows = newArray;

                    this.totalCount = res.data.total_count;
                } catch (err) {
                    this.$toasted.error('Failed to fetch clients roster.');
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter,
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }
                this.criteriaProps = criteria;
                await this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                },
            },
        },
        async created() {
            await this.init();
        },
    };
</script>
